.tag {
  font-size: 12px;
  background-color: var(--ion-color-secondary);
  color: var(--ion-color-secondary-contrast-bw);
  border-radius: 999px;
  padding: 2px 8px;
  text-align: center;
  display: inline-block;
}

/* FEATURE */
.feature_invoice,
.feature_statement,
.feature_ebrochure,
.feature_subcategory,
.feature_file,
.feature_report,
.feature_kongdog,
.feature_showVendor,
.feature_discounts,
.feature_commission,
.feature_refundVoucher,
.feature_regions,
.feature_terms,
.feature_dailyReport,
.feature_notification {
  display: none;
}

body.body_file .feature_file,
body.body_invoice .feature_invoice,
body.body_subcategory .feature_subcategory,
body.body_statement .feature_statement,
body.body_ebrochure .feature_brochure,
body.body_report .feature_report,
body.body_showVendor .feature_showVendor,
body.body_kongdog .feature_kongdog,
body.body_discounts .feature_discounts,
body.body_commission .feature_commission,
body.body_refundVoucher .feature_refundVoucher,
body.body_regions .feature_regions,
body.body_terms .feature_terms,
body.body_dailyReport .feature_dailyReport,
body.body_notification .feature_notification {
  display: initial;
}

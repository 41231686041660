html {
  font-size: 10px;
}

body {
  font-size: 1.4em;
}

.MuiButtonBase-root.MuiButton-root {
  text-transform: initial;
}

.MuiPopover-paper a {
  color: var(--ion-text-color);
}

.MuiTableCell-root div.mod {
  opacity: 0.75;
  font-size: 0.8em;
}
